"use strict";

document.getElementById("play-video").onclick = function () {
  if (window.screen.width >= 900) {
    document.getElementById("video").innerHTML =
      '<iframe width="757" height="426" src="https://www.youtube-nocookie.com/embed/-XM82vK8rVQ?si=IHdWswHoIeoxbX-R?&autoplay=1&mute=1" title="Bookable" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>';
  } else {
    document.getElementById("video").innerHTML =
      '<iframe width="350" height="197" src="https://www.youtube-nocookie.com/embed/-XM82vK8rVQ?si=IHdWswHoIeoxbX-R?&autoplay=1&mute=1" title="Bookable" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>';
  }
};
